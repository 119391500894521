import { useEffect, useState, useCallback } from "react";
import qs from "qs";
import GroupBar from "components/GroupBar";
import { Grid, GridItem, Heading, Spinner, Box } from "@chakra-ui/react";
import { Container, LoadingContainer } from "./styles";
import FeelingChart from "components/FeelingChart";

const baseUrl = process.env.REACT_APP_API;

const ReportGraph = ({ type, title, filters, location }: any) => {
  const [reports, setReport] = useState([]);
  const [loading, setLoading] = useState(true);

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const getReport = useCallback(async () => {
    setLoading(true);
    fetch(
      `${baseUrl}/attendances/persons/${query.personId}/reports?${filters}`,
      {
        mode: "cors",
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "x-company-id": query.companyId as string,
          Authorization: "Bearer " + query.token,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => setReport(data))
      .catch((e) => {
        console.log("RUIM", e.message);
      })
      .finally(() => setLoading(false));
  }, [filters, query.personId, query.token]);

  useEffect(() => {
    getReport();
  }, [getReport]);

  if (loading) {
    return (
      <LoadingContainer>
        <Spinner size="md" color="blue.500" thickness="3px" />
        <Box p={4}>Carregando relatórios...</Box>
      </LoadingContainer>
    );
  }

  return (
    <Grid style={{ height: "100vh" }}>
      {reports.length ? (
        <GridItem colSpan={4}>
          <Container>
            <Heading size="md">{title}</Heading>
          </Container>
          {/* <Filters /> */}
          {!loading ? (
            <>
              {type === "GroupBar" && <GroupBar data={reports} />}
              {type === "FeelingChart" && <FeelingChart data={reports} />}
            </>
          ) : (
            <Container>Carregando relatório...</Container>
          )}
        </GridItem>
      ) : (
        <GridItem colSpan={4} padding={4}>
          <Heading size="sm">Não possui dados.</Heading>
          <p style={{ marginTop: 10 }}>
            Não conseguimos processar um relatório com os dados atuais.
          </p>
        </GridItem>
      )}
    </Grid>
  );
};

export default ReportGraph;
