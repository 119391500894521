import {
  FormControl,
  FormLabel,
  Box,
  RadioGroup,
  Stack,
  Radio,
  Heading,
} from "@chakra-ui/react";
import { useFormikContext, FormikProps } from "formik";
import ButtonFooter from "../../buttons";
import { Health } from "../../type";

function Reason() {
  const { values, setFieldValue }: FormikProps<Health> = useFormikContext();

  const reason = [
    {
      label: "Prevenção e Reabilitação",
      value: "Prevenção e Reabilitação",
    },
    {
      label: "Tratamento de um problema específico",
      value: "Tratamento de um problema específico",
    },
    {
      label: "Indicação médica",
      value: "Indicação médica",
    },
  ];

  return (
    <Box>
      <Heading my={6} size="lg">
        O que você procura?
      </Heading>
      <FormControl as="fieldset">
        <FormLabel as="legend">
          Conte-nos, o que te trouxe a procurar ajuda?
        </FormLabel>
        <RadioGroup
          value={values["reason"]}
          onChange={(value) => setFieldValue("reason", value)}
        >
          <Stack>
            {reason.map((item) => {
              return (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              );
            })}
          </Stack>
        </RadioGroup>
      </FormControl>
      <ButtonFooter step="reason" />
    </Box>
  );
}

export default Reason;
