import {
  FormControl,
  FormLabel,
  Box,
  Stack,
  Text,
  CheckboxGroup,
  Checkbox,
  Heading,
} from "@chakra-ui/react";
import { useFormikContext, FormikProps } from "formik";
import ButtonFooter from "../../buttons";
import { Health } from "../../type";

function Brain() {
  const { values, setFieldValue }: FormikProps<Health> = useFormikContext();

  const DISEASES_BRAIN = [
    "Depressão",
    "Alzheimer",
    "Parkinson",
    "AVC (Derrame)",
  ];

  return (
    <Box>
      <Heading my={6} size="lg">
        Saúde Mental
      </Heading>
      <FormControl as="fieldset">
        <FormLabel as="legend">Como está a saúde mental?</FormLabel>

        <Text mb={4}>
          Marque todas as opções que você considerar que tem relação com seu
          estado atual de saúde.
        </Text>

        <Text mb={4}>
          Você poderá também acrescentar algo no final que não tenha aparecido
          por aqui.
        </Text>

        <CheckboxGroup
          value={values["brain"]}
          onChange={(value) => setFieldValue("brain", value)}
        >
          <Stack>
            {DISEASES_BRAIN.map((item) => {
              return (
                <Checkbox key={item} value={item}>
                  {item}
                </Checkbox>
              );
            })}
          </Stack>
        </CheckboxGroup>
      </FormControl>

      <ButtonFooter step="brain" />
    </Box>
  );
}

export default Brain;
