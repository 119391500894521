import {
  FormControl,
  FormLabel,
  Box,
  Stack,
  Text,
  CheckboxGroup,
  Checkbox,
  Heading,
} from "@chakra-ui/react";
import { useFormikContext, FormikProps } from "formik";
import ButtonFooter from "../../buttons";
import { Health } from "../../type";

function Pulm() {
  const { values, setFieldValue }: FormikProps<Health> = useFormikContext();

  const DISEASES_PULM = [
    "Asma",
    "Enfisema",
    "Pneumonia nos últimos 6 meses",
    "Alergia e/ou Rinite",
  ];

  return (
    <Box>
      <Heading my={6} size="lg">
        Sistema Respiratório
      </Heading>
      <FormControl as="fieldset">
        <FormLabel as="legend">Como está a sua saúde pulmonar?</FormLabel>

        <Text mb={4}>
          Marque todas as opções que você considerar que tem relação com seu
          estado atual de saúde.
        </Text>

        <Text mb={4}>
          Você poderá também acrescentar algo no final que não tenha aparecido
          por aqui.
        </Text>

        <CheckboxGroup
          value={values["pulm"]}
          onChange={(value) => setFieldValue("pulm", value)}
        >
          <Stack>
            {DISEASES_PULM.map((item) => {
              return (
                <Checkbox key={item} value={item}>
                  {item}
                </Checkbox>
              );
            })}
          </Stack>
        </CheckboxGroup>
      </FormControl>
      <ButtonFooter step="pulm" />
    </Box>
  );
}

export default Pulm;
