import { Heading, Text } from "@chakra-ui/react";

interface HeaderProps {
  title: string;
  description?: string;
}

function Header({ title, description }: HeaderProps) {
  return (
    <>
      <Heading
        fontSize={"2xl"}
        sx={{
          "@media print": {
            fontSize: "sm",
          },
        }}
      >
        {title}
      </Heading>
      {description && (
        <Text
          color={"gray.600"}
          fontSize="sm"
          marginTop={0}
          sx={{
            "@media print": {
              marginTop: "0 !important",
              fontSize: "xs",
            },
          }}
        >
          {description}
        </Text>
      )}
    </>
  );
}

export default Header;
