import create from "zustand";
import { devtools } from "zustand/middleware";
import { HealthSteps } from "../pages/health/type.d";

type HealthStepsType = {
  fullName: string;
  setFullName: (value: string) => void;
  healthSteps: HealthSteps;
  setHealthSteps: (value: HealthSteps) => void;
};

const useStore = create<HealthStepsType>(
  devtools((set) => ({
    fullName: "",
    setFullName: (value: string) => set(() => ({ fullName: value })),
    healthSteps: HealthSteps.Welcome,
    setHealthSteps: (value: number) => set(() => ({ healthSteps: value })),
  }))
);

export default useStore;
