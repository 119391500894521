import { Stack, SimpleGrid, Flex, Container, Text } from "@chakra-ui/react";
import { IResumeCurrent } from "models/Resume";
import Header from "../components/Header";

interface DadosHemodinamicosProps {
  current: IResumeCurrent;
}

const DadosHemodinamicos = ({ current }: DadosHemodinamicosProps) => {
  const conditionalString = (value: string, mensure = "", space = true) => {
    if (space) return value ? `${value} ${mensure}` : "Não aferido";
    return value ? `${value}${mensure}` : "Não aferido";
  };

  if (
    !current.evaluation.spo2 &&
    !current.evaluation.spo2_final &&
    !current.evaluation.bloodPressureDia &&
    !current.evaluation.bloodPressureSys &&
    !current.evaluation.bloodPressureDia_final &&
    !current.evaluation.bloodPressureSys_final &&
    !current.evaluation.heartRate &&
    !current.evaluation.heartRate_final
  ) {
    return null;
  }

  const Title = () => (
    <Stack
      mb={8}
      spacing={4}
      as={Container}
      maxW={"3xl"}
      textAlign={"center"}
      sx={{
        "@media print": {
          textAlign: "left",
          my: 0,
          mt: 0,
          p: 0,
        },
      }}
    >
      <Header
        title="Dados gerais e hemodinâmicos"
        description="Dados aferidos durante a avaliação do paciente."
      />
    </Stack>
  );

  const FeelingScore = () => (
    <Stack>
      <Text
        fontWeight={600}
        sx={{
          "@media print": {
            margin: 0,
            padding: 0,
            fontSize: "xs",
            color: "#343333",
          },
        }}
      >
        Estado Geral: 0 a 5
      </Text>
      <Text
        color={"gray.600"}
        sx={{
          "@media print": {
            margin: "0 !important",
            padding: 0,
            fontSize: "xs",
          },
        }}
      >
        {current.evaluation.feelingScore
          ? `${current.evaluation.feelingScore} pontos`
          : "Não aferido"}
      </Text>
    </Stack>
  );

  const Height = () => (
    <Stack>
      <Text
        fontWeight={600}
        sx={{
          "@media print": {
            margin: 0,
            padding: 0,
            fontSize: "xs",
            color: "#343333",
          },
        }}
      >
        Altura
      </Text>
      <Text
        color={"gray.600"}
        sx={{
          "@media print": {
            margin: "0 !important",
            padding: 0,
            fontSize: "xs",
          },
        }}
      >
        {current.evaluation.height
          ? `${current.evaluation.height} cm`
          : "Não aferido"}
      </Text>
    </Stack>
  );

  const Weight = () => (
    <Stack>
      <Text
        fontWeight={600}
        sx={{
          "@media print": {
            margin: 0,
            padding: 0,
            fontSize: "xs",
            color: "#343333",
          },
        }}
      >
        Peso
      </Text>
      <Text
        color={"gray.600"}
        sx={{
          "@media print": {
            margin: "0 !important",
            padding: 0,
            fontSize: "xs",
          },
        }}
      >
        {current.evaluation.weight
          ? `${parseFloat(current.evaluation.weight)} kg`
          : "Não aferido"}
      </Text>
    </Stack>
  );

  const BloodPressure = () => (
    <Stack>
      <Text
        fontWeight={600}
        mt={0}
        sx={{
          "@media print": {
            margin: 0,
            padding: 0,
            fontSize: "xs",
            color: "#343333",
          },
        }}
      >
        Pressão Arterial
      </Text>

      <Text
        color={"gray.600"}
        sx={{
          "@media print": {
            margin: "0 !important",
            padding: 0,
            fontSize: "xs",
          },
        }}
      >
        {conditionalString(current.evaluation.bloodPressureSys, "")}
        {" / "}
        {conditionalString(current.evaluation.bloodPressureDia, "mmhg")}
      </Text>
    </Stack>
  );

  const Spo = () => (
    <Stack>
      <Text
        fontWeight={600}
        sx={{
          "@media print": {
            margin: 0,
            padding: 0,
            fontSize: "xs",
            color: "#343333",
          },
        }}
      >
        Saturação Oxigênio
      </Text>
      <Text
        color={"gray.600"}
        sx={{
          "@media print": {
            margin: "0 !important",
            padding: 0,
            fontSize: "xs",
          },
        }}
      >
        {conditionalString(current.evaluation.spo2, "%", false)}
        {" / "}
        {conditionalString(current.evaluation.spo2_final, "%", false)}
      </Text>
    </Stack>
  );

  const HeartRate = () => (
    <Stack>
      <Text
        fontWeight={600}
        sx={{
          "@media print": {
            margin: 0,
            padding: 0,
            fontSize: "xs",
            color: "#343333",
          },
        }}
      >
        Frequência Cardíaca
      </Text>
      <Text
        color={"gray.600"}
        sx={{
          "@media print": {
            margin: "0 !important",
            padding: 0,
            fontSize: "xs",
          },
        }}
      >
        {conditionalString(current.evaluation.heartRate, "")} bpm
        {" / "}
        {conditionalString(current.evaluation.heartRate_final, "")}
      </Text>
    </Stack>
  );

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="center"
        mt={5}
        p={{
          base: "2",
          md: "4",
        }}
        sx={{
          "@media print": {
            p: 0,
            mt: 0,
          },
        }}
      >
        <Title />
        <SimpleGrid
          columns={{ base: 2, md: 3 }}
          spacing={10}
          sx={{
            "@media print": {
              m: 0,
              p: 0,
              mt: 2,
              "grid-template-columns": "repeat(3, minmax(0, 1fr))",
            },
          }}
        >
          <FeelingScore />
          <Height />
          <Weight />
          <BloodPressure />
          <HeartRate />
          <Spo />
        </SimpleGrid>
      </Flex>
    </>
  );
};

export default DadosHemodinamicos;
