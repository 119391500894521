/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  FormControl,
  FormLabel,
  Box,
  Stack,
  Radio,
  Text,
  CheckboxGroup,
  Checkbox,
  Heading,
} from "@chakra-ui/react";
import { useFormikContext, FormikProps } from "formik";
import ButtonFooter from "../../buttons";
import { Health } from "../../type";

function Cardio() {
  const { values, setFieldValue }: FormikProps<Health> = useFormikContext();

  const DISEASES_CARDIO = [
    "Pressão arterial alta",
    "Insuficiência cardíaca",
    "Cirurgia no coração",
    "Doença de Chagas",
    "Infarto do coração",
    "Diabetes",
    "Colesterol elevado",
  ];

  return (
    <Box>
      <Heading my={6} size="lg">
        Sistema Cardiovascular
      </Heading>
      <FormControl as="fieldset">
        <FormLabel as="legend">Como está a sua saúde cardiovascular?</FormLabel>

        <Text mb={4}>
          Marque todas as opções que você considerar que tem relação com seu
          estado atual de saúde.
        </Text>

        <Text mb={4}>
          Você poderá também acrescentar algo no final que não tenha aparecido
          por aqui.
        </Text>

        <CheckboxGroup
          value={values["cardio"]}
          onChange={(value) => setFieldValue("cardio", value)}
        >
          <Stack>
            {DISEASES_CARDIO.map((item) => {
              return (
                <Checkbox key={item} value={item}>
                  {item}
                </Checkbox>
              );
            })}
          </Stack>
        </CheckboxGroup>
      </FormControl>

      <ButtonFooter step="cardio" />
    </Box>
  );
}

export default Cardio;
