/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { Container, Heading, Text, Box } from "@chakra-ui/react";
import { Formik, Form, FormikHelpers } from "formik";
import { Health } from "./type.d";
import { StepsArray } from "./steps";
import useStore from "../../store/health";
import Logo from "../../assets/images/logo_app.png";
import qs from "qs";
import { useEffect, useState } from "react";
import HealthSchema from "./validation";

const HealthNew = (props: any) => {
  const [, setParsedToken]: any = useState();
  const [errorToken, setErrorToken]: any = useState(null);
  const [success, setSuccess]: any = useState(false);

  const healthSteps = useStore((state) => state.healthSteps);
  const setFullName = useStore((state) => state.setFullName);

  const query = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });

  const TitlePage = ({ showSteps = true }: { showSteps?: boolean }) => {
    return (
      <Box
        mb={6}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <img width="50" src={Logo} alt="3id App" />
        {showSteps && <CountSteps />}
      </Box>
    );
  };

  const StepRender = () => StepsArray[healthSteps]();

  const CountSteps = () => {
    if (!healthSteps) return null;
    return (
      <Heading size="lg">
        {healthSteps} de {StepsArray.length - 1}
      </Heading>
    );
  };

  const parseJwt = (token: any) => {
    try {
      const jwt = JSON.parse(atob(token.split(".")[1]));
      const timeNow = Date.now() / 1000;
      if (jwt.exp < timeNow) throw new Error("expired");

      return jwt;
    } catch (e) {
      return null;
    }
  };

  const saveForm = async ({ values, setSubmitting }: any) => {
    try {
      setSubmitting(true);
      const response = await fetch(
        `${process.env.REACT_APP_API}/health/persons/attendance`,
        {
          mode: "cors",
          method: "PUT",
          body: JSON.stringify({
            ...values,
          }),
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${query.token}` || "",
          },
        }
      );
      setSuccess(true);
    } catch (err) {
      setSuccess(false);
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (query.token && errorToken === null) {
      try {
        const parsedToken = parseJwt(query.token);
        setFullName(parsedToken.data.fullName);
        setParsedToken({ token: query.token, ...parsedToken });
      } catch {
        setErrorToken("expired");
      }
    }
  }, [query.token, setFullName, errorToken]);

  if (success) {
    return (
      <Container maxW="xl" centerContent>
        <Box p={4}>
          <TitlePage showSteps={false} />
          <Heading size="md">Pré-atendimento enviado com sucesso.</Heading>
          <Text mt="4">
            Pode ficar tranquilo, agora é so esperar o dia e hora da sua
            consulta ou avaliação.
          </Text>
          <Text mt="4">
            O seus dados estão seguros e com eles conseguimos adiantar muito o
            seu atendimento.
          </Text>

          <Text mt="8">Caso tenha alguma dúvida entre em contato conosco.</Text>

          <Text mt="2">Desde já agradecemos.</Text>
        </Box>
      </Container>
    );
  }

  if (errorToken === "expired")
    return "Formulário passou do prazo de validade, entre em contato para solicitar um novo link.";

  if (!query.token) return "Sem permissão ou token";

  return (
    <Container maxW="xl" centerContent>
      <Formik
        initialValues={{
          formFilledBy: "Client",
        }}
        validateOnChange
        validationSchema={HealthSchema}
        onSubmit={(
          values: Health,
          { setSubmitting }: FormikHelpers<Health>
        ) => {
          saveForm({ values, setSubmitting });
        }}
      >
        <Form style={{ width: "100% " }}>
          <Box p={4}>
            <TitlePage />
            <StepRender />
          </Box>
        </Form>
      </Formik>
    </Container>
  );
};

export default HealthNew;
