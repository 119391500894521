import { Box } from "@chakra-ui/react";

const Space = () => (
  <Box
    mt="10"
    sx={{
      "@media print": {
        my: 0,
      },
    }}
  >
    <hr />
  </Box>
);

export default Space;
