import { Heading, Text, Box } from "@chakra-ui/react";
import useStore from "../../../../store/health";
import ButtonFooter from "../../buttons";

function Welcome() {
  const fullName = useStore((state: any) => state.fullName);

  return (
    <Box>
      <Heading my={4} as="h1" size="lg">
        Olá, {fullName}
      </Heading>
      <Heading size="md" my="4">
        Seja bem vindo(a)!
      </Heading>
      <Text my={4}>
        Antes de você ser avaliado por um profissional, é importante sabermos um
        pouco mais sobre você!
      </Text>
      <Text my={4} fontWeight="bold">
        Preencha as questões adiante com bastante cuidado.
      </Text>
      <Text my={4}>
        Se tiver dúvidas, peça ajuda a alguém da sua família durante o
        preenchimento da ficha ou entre em contato com nossa clínica.
      </Text>
      <ButtonFooter />
    </Box>
  );
}

export default Welcome;
