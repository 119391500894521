import {
  FormControl,
  FormLabel,
  Box,
  Textarea,
  Heading,
} from "@chakra-ui/react";
import { useFormikContext, FormikProps } from "formik";
import ButtonFooter from "../../buttons";
import { Health } from "../../type";

function Medicine() {
  const { values, setFieldValue }: FormikProps<Health> = useFormikContext();

  return (
    <Box>
      <Heading my={6} size="lg">
        Faz uso de algum medicamento?
      </Heading>
      <FormControl as="fieldset">
        <FormLabel as="legend">
          Conte-nos aqui quais medicamentos você utiliza?
        </FormLabel>
        <Textarea
          value={values["medicine"]}
          onChange={(event) => setFieldValue("medicine", event.target.value)}
          size="sm"
        />
      </FormControl>

      <ButtonFooter step="medicine" />
    </Box>
  );
}

export default Medicine;
