import create from "zustand";
import { devtools } from "zustand/middleware";
import qs from "qs";
import { parseJwt } from "utils/parseJwt";

type EvaluationResumeType = {
  token: any;
  setToken: (value: any) => void;
};

const useStore = create<EvaluationResumeType>(
  devtools((set) => ({
    token: null,
    setToken: (value: any) => {
      const query = qs.parse(value, { ignoreQueryPrefix: true });
      const parsed = parseJwt(query.token);
      const token = { access_token: query.token, parsed };
      set(() => ({ token }));
    },
  }))
);

export default useStore;
