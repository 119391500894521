import { Box, Heading, Text } from "@chakra-ui/react";
import { useFormikContext, FormikProps } from "formik";
import { Health } from "../../type";
import { ScoreBoxContainer, Score, ScoreBox, ScoreText } from "./styles";

import Score1 from "../../../../assets/images/score/1.png";
import Score2 from "../../../../assets/images/score/2.png";
import Score3 from "../../../../assets/images/score/3.png";
import Score4 from "../../../../assets/images/score/4.png";
import Score5 from "../../../../assets/images/score/5.png";
import ButtonFooter from "../../buttons";

function FeelingScore() {
  const { values, setFieldValue }: FormikProps<Health> = useFormikContext();

  const scores = [
    {
      color: "#ea5041",
      img: Score1,
      value: 1,
    },
    {
      color: "#f28d54",
      img: Score2,
      value: 2,
    },
    {
      color: "#ffd254",
      img: Score3,
      value: 3,
    },
    {
      color: "#bacf69",
      img: Score4,
      value: 4,
    },
    {
      color: "#42ae5c",
      img: Score5,
      value: 5,
    },
  ];

  return (
    <Box>
      <Heading my={6} size="lg">
        Uma nota para sua saúde.
      </Heading>
      <Text>Como está sua percepção da sua saúde atual? (0 - 5)</Text>
      <ScoreBoxContainer>
        {scores.map((item) => {
          const current = values.feelingScore === item.value;
          return (
            <ScoreBox
              key={item.value}
              current={current}
              onClick={() => setFieldValue("feelingScore", item.value)}
            >
              <Score bgColor={item.color} key={item.value}>
                <img
                  width="32"
                  height="32"
                  src={item.img}
                  alt={item.value.toString()}
                />
              </Score>
              <ScoreText category="c2" current={current}>
                {item.value}
              </ScoreText>
            </ScoreBox>
          );
        })}
      </ScoreBoxContainer>
      <ButtonFooter step="feelingScore" />
    </Box>
  );
}

export default FeelingScore;
