import {
  FormControl,
  FormLabel,
  Box,
  Textarea,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useFormikContext, FormikProps } from "formik";
import ButtonFooter from "../../buttons";
import { Health } from "../../type";

function Expectation() {
  const { values, setFieldValue }: FormikProps<Health> = useFormikContext();

  return (
    <Box>
      <Heading my={6} size="lg">
        Expectativas e resultado
      </Heading>
      <FormControl as="fieldset">
        <FormLabel as="legend">
          Quais são as suas expectativas em relação ao seu atendimento na nossa
          clínica?
        </FormLabel>

        <Text>O que você espera melhorar?</Text>
        <Text>Em quanto tempo?</Text>
        <Textarea
          value={values["expectation"]}
          onChange={(event) => setFieldValue("expectation", event.target.value)}
          size="sm"
        />
      </FormControl>

      <ButtonFooter step="expectation" />
    </Box>
  );
}

export default Expectation;
