import { Heading, Text, Flex } from "@chakra-ui/react";
import { IEvaluation } from "models/Evaluation";
import attendanceUtils from "utils/attendanceUtils";

interface ResultadoProps {
  evaluation: IEvaluation;
}

function Resultado({ evaluation }: ResultadoProps) {
  // classificação
  const classification = attendanceUtils.classification(
    evaluation.totalClassification
  );

  if (!classification.character) return null;

  const colors = {
    success: "green",
    warning: "yellow",
    danger: "red",
  } as any;

  return (
    <>
      <Flex
        alignItems="center"
        gap={4}
        mt={4}
        sx={{
          flexDirection: {
            base: "column",
            md: "row",
          },
          "@media print": {
            mt: 4,
            p: 0,
          },
        }}
      >
        <Flex
          style={{
            width: 80,
            height: 80,
            borderRadius: 50,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: colors[classification?.color || "warning"],
          }}
          sx={{
            "@media print": {
              display: "none",
            },
          }}
        >
          <Text fontSize="5xl" fontWeight={"bold"} style={{ color: "white" }}>
            {classification.character}
          </Text>
        </Flex>
        <Flex flex={1} flexDirection="column" p={2}>
          <Heading
            size="md"
            mb={1}
            sx={{
              "@media print": {
                fontSize: "sm",
              },
            }}
          >
            {classification.label}
          </Heading>
          <Text
            sx={{
              "@media print": {
                fontSize: "xs",
              },
            }}
          >
            {classification.description}
          </Text>
        </Flex>
      </Flex>
    </>
  );
}

export default Resultado;
