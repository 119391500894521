import { Box, Stack, SimpleGrid } from "@chakra-ui/react";
import { Container } from "rsuite";
import { IEvaluation } from "models";

import Space from "components/Space";
import Header from "../components/Header";
import StatsCard, { StatsCardProps } from "./StatsCard";
import Resultado from "./Resultado";

interface TestesClassificacaoProps {
  evaluation: IEvaluation;
}

export const IMSOptions = [
  { label: "10", value: 5 },
  { label: "9", value: 4 },
  { label: "8 - 7", value: 3 },
  { label: "6 - 5", value: 2.5 },
  { label: "4 - 3", value: 1.5 },
  { label: "2 a 0", value: 1 },
];

export const TUGOptions = [
  { label: "< 10", value: 5 },
  { label: "10 a 19", value: 4 },
  { label: "> 20", value: 3 },
  { label: "não se aplica IMS 6 ou 5", value: 2.5 },
  { label: "não se aplica IMS 4 ou 3", value: 1.5 },
  { label: "não se aplica IMS 3 a 0", value: 1 },
];

export const SupportUnipodalOptions = [
  { label: "> 10", value: 5 },
  { label: "5 a 10 ", value: 4 },
  { label: "< 5/redução base", value: 3 },
  { label: "não se aplica IMS 6 ou 5", value: 2.5 },
  { label: "não se aplica IMS 4 ou 3", value: 1.5 },
  { label: "não se aplica IMS 2 ou 1 ou 1", value: 1 },
];

export const MRCOptions = [
  { label: "60 a 54", value: 5 },
  { label: "53 a 48", value: 4 },
  { label: "47 a 36", value: 3 },
  { label: "35 a 24", value: 2.5 },
  { label: "23 a 12", value: 1.5 },
  { label: "11 a 0", value: 1 },
];

export const GFMMIOptions = [
  { label: "GV: flexão plantar sem apoio", value: 5 },
  { label: "GIV: sentar/levantar com apoio", value: 4 },
  { label: "GIII: flexão quadril bípede com apoio", value: 3 },
  { label: "GII: não realiza flexão quadril sentado", value: 2.5 },
  { label: "GI: esboço de contração MI sentado/deitado", value: 1.5 },
  { label: "G0: não movimenta", value: "G0: não movimenta" },
  { label: "Não se aplica", value: "Não se aplica" },
];

export const GFMMSOptions = [
  { label: "GV: flexão ombro segurando garrafa 1 litro", value: "fake_a" },
  { label: "GIV: flexão ombro segurando chinelo/sapato", value: "fake_b" },
  { label: "GIII: flexão ombro livre sentado", value: "fake_c" },
  { label: "GII: não realiza flexão ombro sentado", value: "fake_d" },
  { label: "GI: esboço de contração MI sentado/deitado", value: "fake_e" },
  { label: "G0: não movimenta", value: "fake_f" },
  { label: "Não se aplica", value: "Não se aplica" },
];

export const STSOptions = [
  { label: "≥ 15", value: 5 },
  { label: "10 a 14", value: 4 },
  { label: "5 a 9", value: 3 },
  { label: "0 a 4", value: 2.5 },
  { label: "não se aplica IMS 4 ou 3", value: 1.5 },
  { label: "não se aplica IMS 2 ou 1 ou 0", value: 1 },
];

export const CognitiveOptions = [
  { label: "Compreende", value: 5 },
  { label: "Compreende c/ lentidão", value: 4 },
  { label: "Compreende pouco", value: 3 },
  { label: "Não compreende IMS 5 ou 6", value: 2.5 },
  { label: "Não compreende IMS 4 ou 3", value: 1.5 },
  { label: "Não responde aos comandos", value: 1 },
];

const TestesClassificacao = ({ evaluation }: TestesClassificacaoProps) => {
  const getLabel = (options: any, value?: number | string) => {
    if (!value) return undefined;
    const option = options.find((item: any) => item.value === value);
    return option?.label;
  };

  const statCard: StatsCardProps[] = [
    {
      title: "IMS (nº)",
      stat: getLabel(IMSOptions, evaluation.classification?.ims),
    },
    {
      title: "TUG (s)",
      stat: getLabel(TUGOptions, evaluation.classification?.tug),
    },
    {
      title: "Apoio Unipodal",
      stat: getLabel(
        SupportUnipodalOptions,
        evaluation.classification?.supportUnipodal
      ),
    },
    {
      title: "MRC: Presencial",
      stat: getLabel(MRCOptions, evaluation.classification?.mrc),
    },
    {
      title: "GFM MI: Observação",
      stat: getLabel(GFMMIOptions, evaluation.classification?.gfm_mi),
    },
    {
      title: "GFM MS: Observação",
      stat: getLabel(GFMMSOptions, evaluation.classification?.gfm_ms),
    },
    {
      title: "Sit to Stand",
      stat: getLabel(STSOptions, evaluation.classification?.sts),
    },
    {
      title: "Cognitivo S5Q",
      stat: getLabel(
        CognitiveOptions,
        evaluation.classification?.cognitive_s5q
      ),
    },
  ];

  if (statCard.some((item) => item.stat === undefined)) return null;

  console.log({ statCard });

  return (
    <>
      <Space />
      <Stack
        mb={8}
        mt={8}
        spacing={4}
        as={Container}
        maxW="3xl"
        textAlign="center"
        sx={{
          "@media print": {
            textAlign: "left",
            my: 0,
            mt: 0,
            px: 2,
          },
        }}
      >
        <Header title="Classificação Funcional 3ID" />
      </Stack>
      <Resultado evaluation={evaluation} />
      <Box padding={2} mt={6}>
        <SimpleGrid
          columns={{ base: 2, md: 3 }}
          spacing={{ base: 5, lg: 8 }}
          sx={{
            "@media print": {
              p: 0,
              mt: 2,
              "grid-template-columns": "repeat(4, minmax(0, 1fr))",
            },
          }}
        >
          {statCard.map((item) => {
            return (
              <StatsCard
                key={item.title}
                title={item.title}
                mensure={item.mensure}
                stat={item.stat}
                icon={item?.icon}
              />
            );
          })}
        </SimpleGrid>
      </Box>
    </>
  );
};

export default TestesClassificacao;
