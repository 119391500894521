import {
  FormControl,
  FormLabel,
  Box,
  RadioGroup,
  Stack,
  Radio,
  Text,
  Heading,
} from "@chakra-ui/react";
import { useFormikContext, FormikProps } from "formik";
import ButtonFooter from "../../buttons";
import { Health } from "../../type";

function Dependent() {
  const { values, setFieldValue }: FormikProps<Health> = useFormikContext();

  const DEPENDENT_VALUES = [
    {
      label: "Precisa de auxílio total",
      value: "total",
    },
    {
      label: "Necessita de auxílio parcial",
      value: "partial",
    },
    {
      label: "Não necessita de auxílio",
      value: "none",
    },
  ];

  return (
    <Box>
      <Heading my={6} size="lg">
        Qualidade de vida
      </Heading>
      <FormControl as="fieldset">
        <FormLabel as="legend">
          Conte-nos sobre sua qualidade de vida?
        </FormLabel>

        <Text mb={4}>
          Como está sua atividade de vida diária? (exemplos: alimentar-se, tomar
          banho, vestir-se, transferir-se de um lugar para outro etc)
        </Text>
        <RadioGroup
          value={values["dependent"]}
          onChange={(value) => setFieldValue("dependent", value)}
        >
          <Stack>
            {DEPENDENT_VALUES.map((item) => {
              return (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              );
            })}
          </Stack>
        </RadioGroup>
      </FormControl>

      <ButtonFooter step="dependent" />
    </Box>
  );
}

export default Dependent;
