export interface Health {
  key?: string;
  uid?: string;
  displayName?: string;
  // Form filled up info
  formFilledBy?: string;
  formResponsibleName?: string;
  formResponsibleRelation?: string;
  reason?: string;
  feelingScore?: number;
  cardio?: string[];
  pulm?: string[];
  smoke?: string;
  osteo?: string[];
  brain?: string[];
  general?: string[];
  other?: string;
  medicine?: string;
  // QOL
  dependent?: string;
  fall?: string;
  // Habits
  active?: string;
  expectation?: string;
}

export enum HealthSteps {
  Welcome,
  Responsible,
  Reason,
  FeelingScore,
  Cardio,
  Pulm,
  Smoke,
  Osteo,
  Brain,
  General,
  Dependent,
  Fall,
  Activity,
  Medicine,
  Other,
  Expectation,
}
