import qs from "qs";
import ReportGraph from "containers/ReportGraph";

const filters = qs.stringify({
  key: "createdAt",
  value: "feelingScore",
});

const HeartRate = ({ location }: any) => {
  return (
    <ReportGraph
      type="FeelingChart"
      title="Estado Geral"
      filters={filters}
      location={location}
    />
  );
};

export default HeartRate;
