import { Stat, Flex, Box, StatLabel, StatNumber, Text } from "@chakra-ui/react";

export interface StatsCardProps {
  title: string;
  subtitle?: string | undefined;
  stat: any | undefined;
  icon?: any;
  mensure?: string;
  iconStyle?: any;
}

function StatsCard(props: StatsCardProps) {
  const {
    title,
    stat,
    icon,
    subtitle,
    mensure,
    iconStyle = { backgroundColor: "transparent" },
  } = props;
  if (!stat) return null;

  const LayoutToPrint = () => (
    <Box
      sx={{
        display: "none",
        "@media print": {
          display: "block",
        },
      }}
    >
      <Text fontWeight="bold" fontSize="xs" color="#343333">
        {title}
      </Text>
      <Text fontSize="xs">
        {stat} {mensure}
      </Text>
      <Text fontSize="x-small">{subtitle}</Text>
    </Box>
  );

  const LayoutToMobile = () => (
    <Stat
      px={{ base: 2, md: 4 }}
      py={"5"}
      shadow="base"
      border={"1px solid #eaeaea"}
      rounded={"lg"}
      sx={{
        "@media print": {
          display: "none",
        },
      }}
    >
      <Flex justifyContent="space-between" alignItems="flex-start">
        <Box>
          <StatLabel fontWeight="bold" fontSize="sm">
            {title}
          </StatLabel>
          <Text fontSize="xx-small">{subtitle}</Text>
          <StatNumber
            fontSize={"xl"}
            fontWeight={"medium"}
            display="flex"
            alignItems="flex-end"
          >
            <Box mt={3}>
              <Text fontSize="sm" color="#5d5d5d" mb={1}>
                {stat}
              </Text>
            </Box>
            <Box ml={1} pb={0} justifyContent="flex-end" alignItems="flex-end">
              <Text fontSize="xs" mb={1}>
                {mensure}
              </Text>
            </Box>
          </StatNumber>
        </Box>
        {icon && (
          <Box alignContent="center" alignItems="center">
            <img
              style={{ width: 60, height: 60, maxWidth: 80, ...iconStyle }}
              src={icon}
              alt={title}
            />
          </Box>
        )}
      </Flex>
    </Stat>
  );

  return (
    <>
      <LayoutToPrint />
      <LayoutToMobile />
    </>
  );
}

export default StatsCard;
