import {
  Heading,
  FormControl,
  FormLabel,
  Box,
  RadioGroup,
  Stack,
  Radio,
  Input,
} from "@chakra-ui/react";
import { useFormikContext, FormikProps } from "formik";
import { useEffect } from "react";
import { Health } from "../../type";
import ButtonFooter from "../../buttons";

function Responsible() {
  const { values, setFieldValue }: FormikProps<Health> = useFormikContext();

  const formFilledBy = [
    {
      label: "Sou o paciente",
      value: "Client",
    },
    {
      label: "Responsável pelo Paciente",
      value: "Responsible",
      parse: {
        Responsible: "Responsável",
      },
    },
  ];

  const formResponsibleRelation = [
    {
      label: "Filho/Filha",
      value: "Filho/Filha",
    },
    {
      label: "Irmão/Irmã",
      value: "Irmão/Irmã",
    },
    {
      label: "Pai/Mãe",
      value: "Pai/Mãe",
    },
    {
      label: "Cuidador",
      value: "Cuidador",
    },
    {
      label: "Amigo/Conhecido",
      value: "Amigo/Conhecido",
    },
    {
      label: "Outro",
      value: "Outro",
    },
  ];

  useEffect(() => {
    if (values.formFilledBy === "Client") {
      setFieldValue("formResponsibleName", "");
      setFieldValue("formResponsibleRelation", "");
    }
  }, [setFieldValue, values.formFilledBy]);

  return (
    <>
      <Heading my={6} size="lg">
        Atendimento
      </Heading>
      <Box mb={4}>
        <FormControl as="fieldset">
          <FormLabel as="legend">
            Conte-nos, quem irá preencher seus dados de atendimento:
          </FormLabel>
          <RadioGroup
            value={values["formFilledBy"]}
            onChange={(value) => setFieldValue("formFilledBy", value)}
          >
            <Stack>
              {formFilledBy.map((item) => {
                return (
                  <Radio key={item.value} value={item.value}>
                    {item.label}
                  </Radio>
                );
              })}
            </Stack>
          </RadioGroup>
        </FormControl>
      </Box>

      {values.formFilledBy === "Responsible" && (
        <>
          <Box mb={4}>
            <FormControl as="fieldset">
              <FormLabel as="legend">Nome do Responsável</FormLabel>
              <Input
                variant="outline"
                value={values["formResponsibleName"]}
                onChange={(event) =>
                  setFieldValue("formResponsibleName", event.target.value)
                }
              />
            </FormControl>
          </Box>
          <Box mb={4}>
            <FormControl as="fieldset">
              <FormLabel as="legend">Parentesco do Responsável</FormLabel>
              <RadioGroup
                value={values["formResponsibleRelation"]}
                onChange={(value) =>
                  setFieldValue("formResponsibleRelation", value)
                }
              >
                <Stack>
                  {formResponsibleRelation.map((item) => {
                    return (
                      <Radio key={item.value} value={item.value}>
                        {item.label}
                      </Radio>
                    );
                  })}
                </Stack>
              </RadioGroup>
            </FormControl>
          </Box>
        </>
      )}

      <ButtonFooter step="formFilledBy" />
    </>
  );
}

export default Responsible;
