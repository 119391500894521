import { CheckIcon } from "@chakra-ui/icons";
import {
  Container,
  Stack,
  SimpleGrid,
  HStack,
  Box,
  Icon,
  VStack,
  Text,
} from "@chakra-ui/react";
import Space from "components/Space";
import { IEvaluation } from "models/Evaluation";
import Header from "../components/Header";

interface AvaliacaoInicialProps {
  evaluation: IEvaluation;
}

const AvaliacaoInicial = ({ evaluation }: AvaliacaoInicialProps) => {
  if (
    !evaluation.diagnosisFuncional &&
    !evaluation.comorbidities &&
    !evaluation.mainComplaint &&
    !evaluation.diseaseHistory &&
    !evaluation.gaitAnalysis
  ) {
    return null;
  }

  const ItemRender = ({ title, text }: { title: string; text: string }) => {
    if (!text) return null;
    return (
      <HStack align={"top"}>
        <Box color={"green.400"} px={2}>
          <Icon as={CheckIcon} />
        </Box>
        <VStack align={"start"}>
          <Text
            fontWeight={600}
            sx={{
              "@media print": {
                m: "0 !important",
                p: 0,
                fontSize: "xs",
                color: "#343333",
              },
            }}
          >
            {title}
          </Text>
          <Text
            color={"gray.600"}
            sx={{
              "@media print": {
                m: "0 !important",
                p: 0,
                fontSize: "xs",
              },
            }}
          >
            {text}
          </Text>
        </VStack>
      </HStack>
    );
  };

  return (
    <>
      <Space />
      <Stack
        spacing={4}
        mt={8}
        as={Container}
        maxW={"3xl"}
        textAlign={"center"}
        sx={{
          "@media print": {
            my: 0,
            mt: 0,
            px: 3,
            textAlign: "left",
          },
        }}
      >
        <Header
          title="Avaliação Inicial"
          description="Dados representativos da avaliação inicial, com eles conseguimos
          identificar como esta o estado inicial do paciente."
        />
      </Stack>

      <Container
        maxW={"6xl"}
        mt={10}
        sx={{
          "@media print": {
            margin: 2,
          },
        }}
      >
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 2 }}
          spacing={10}
          sx={{
            "@media print": {
              margin: 0,
              padding: 0,
              gap: 2,
              "grid-template-columns": "repeat(2, minmax(0, 1fr))",
            },
          }}
        >
          <ItemRender
            title="Diagnostico Principal"
            text={evaluation.diagnosisFuncional}
          />
          <ItemRender
            title="Comorbidades Associadas"
            text={evaluation.comorbidities}
          />

          <ItemRender
            title="Queixa Principal"
            text={evaluation.mainComplaint}
          />

          <ItemRender
            title="Análise de Marcha"
            text={evaluation.gaitAnalysis}
          />
        </SimpleGrid>
        <Box
          mt={12}
          sx={{
            "@media print": {
              mt: 4,
            },
          }}
        >
          <ItemRender
            title="Histórico Clínico"
            text={evaluation.diseaseHistory}
          />
        </Box>
      </Container>
    </>
  );
};

export default AvaliacaoInicial;
