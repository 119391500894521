import {
  Stack,
  TableContainer,
  Tbody,
  Tr,
  Td,
  Container,
  Table,
  Text,
  Box,
} from "@chakra-ui/react";
import DateUtils from "utils/dateUtils";
import AttendanceUtils from "utils/attendanceUtils";
import { IResumeCurrent } from "models/Resume";
import PersonUtils from "utils/personUtils";
import Header from "../components/Header";
import { IPerson } from "models/Person";

interface DadosAvaliaçãoProps {
  current: IResumeCurrent;
  person: IPerson;
}

const DadosAvaliacao = ({ current, person }: DadosAvaliaçãoProps) => {
  // atendimento
  const dataAtendimento = DateUtils.formatStringToBr(current.createdAt, true);
  const localAtendimento = AttendanceUtils.parseLocation(current.locationType);

  // person
  const fullName = PersonUtils.capitalizeName(person.fullName);
  const gender = PersonUtils.getGender(person);
  const birthDay = PersonUtils.getBirthday(person);
  const ageString = PersonUtils.getFullAge(person);

  // profissional
  const professional = {
    fullName: PersonUtils.capitalizeName(current.professional.fullName),
    occupation: current.professional.occupation,
    registration: current.professional.registration,
  };

  return (
    <>
      <Stack
        my={8}
        spacing={4}
        as={Container}
        maxW={"3xl"}
        textAlign={"center"}
        sx={{
          "@media print": {
            textAlign: "left",
            m: 0,
            p: 0,
          },
        }}
      >
        <Header
          title="Resumo da Avaliação"
          description="Dados da Avaliação, profissional e local de atendimento."
        />
      </Stack>

      <Box
        display="flex"
        gap={4}
        px={4}
        py={2}
        sx={{
          display: "none",
          "@media print": {
            display: "flex",
            py: 0,
            p: 0,
            m: 0,
          },
        }}
      >
        <Box>
          <Box display="flex" gap={2}>
            <Box>
              <Text fontSize="xs" fontWeight="bold">
                Nome:{" "}
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs">{fullName}</Text>
            </Box>
          </Box>
          <Box display="flex" gap={2}>
            <Box>
              <Text fontSize="xs" fontWeight="bold">
                Data/Hora:{" "}
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs">
                {dataAtendimento.split(" ")[1]} -{" "}
                {dataAtendimento.split(" ")[0]}
              </Text>
            </Box>
          </Box>

          <Box display="flex" gap={2}>
            <Box>
              <Text fontSize="xs" fontWeight="bold">
                Profissional:
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs">{professional.fullName}</Text>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box display="flex" gap={2}>
            <Box>
              <Text fontSize="xs" fontWeight="bold">
                Idade:{" "}
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs">
                {gender} - {ageString} - {birthDay}
              </Text>
            </Box>
          </Box>

          {localAtendimento && localAtendimento !== "other" && (
            <Box display="flex" gap={2}>
              <Box>
                <Text fontSize="xs" fontWeight="bold">
                  Local:{" "}
                </Text>
              </Box>
              <Box>
                <Text fontSize="xs">{localAtendimento}</Text>
              </Box>
            </Box>
          )}

          {professional.registration && (
            <Box display="flex" gap={2}>
              <Box>
                <Text fontSize="xs" fontWeight="bold">
                  Registro Profissional
                </Text>
              </Box>
              <Box>
                <Text fontSize="xs">{professional.registration}</Text>
              </Box>
            </Box>
          )}

          {professional.occupation && (
            <Box display="flex" gap={2}>
              <Box>
                <Text fontSize="xs" fontWeight="bold">
                  Especialidade do Prof.
                </Text>
              </Box>
              <Box>
                <Text fontSize="xs">{professional.occupation}</Text>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          "@media print": {
            display: "none",
          },
        }}
      >
        <TableContainer p={0}>
          <Table size="sm">
            <Tbody>
              <Tr>
                <Td>Nome</Td>
                <Td>{fullName}</Td>
              </Tr>
              <Tr>
                <Td>Idade</Td>
                <Td>
                  {gender} - {ageString} - {birthDay}
                </Td>
              </Tr>
            </Tbody>
            <Tbody>
              <Tr>
                <Td>Data/Hora</Td>
                <Td>
                  {dataAtendimento.split(" ")[1]} -{" "}
                  {dataAtendimento.split(" ")[0]}
                </Td>
              </Tr>
              <Tr>
                <Td>Local</Td>
                <Td> {localAtendimento}</Td>
              </Tr>
              <Tr>
                <Td>Profissional</Td>
                <Td>{professional.fullName}</Td>
              </Tr>
              {professional.registration && (
                <Tr>
                  <Td>Registro Profissional</Td>
                  <Td>{professional.registration}</Td>
                </Tr>
              )}

              {professional.occupation && (
                <Tr>
                  <Td>Especialidade do Prof.</Td>
                  <Td>{professional.occupation}</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default DadosAvaliacao;
