import { Box, Button, ButtonGroup } from "@chakra-ui/react";
import { StepsArray } from "./steps";
import useStore from "../../store/health";
import { useFormikContext } from "formik";

const ButtonFooter = ({ step = "" }: { step?: string }) => {
  const { errors, isSubmitting }: any = useFormikContext();
  const healthSteps = useStore((state) => state.healthSteps);
  const setHealthSteps = useStore((state) => state.setHealthSteps);

  const total = StepsArray.length - 1;
  const lastStep = healthSteps === total;
  const next = healthSteps + 1;
  const prev = healthSteps - 1;

  if (lastStep) {
    return (
      <Box mt={8}>
        <ButtonGroup justifyContent="space-between" display="flex">
          <Button onClick={() => setHealthSteps(prev)}>Voltar</Button>
          <Button
            colorScheme="green"
            size="md"
            type="submit"
            disabled={isSubmitting}
          >
            Finalizar
          </Button>
        </ButtonGroup>
      </Box>
    );
  }

  if (healthSteps === 0)
    return (
      <Box mt={8}>
        <Button
          colorScheme="blue"
          size="md"
          onClick={() => setHealthSteps(healthSteps + 1)}
        >
          Começar
        </Button>
      </Box>
    );

  return (
    <Box mt={8}>
      <ButtonGroup justifyContent="space-between" display="flex">
        <Button variant="outline" onClick={() => setHealthSteps(prev)}>
          Voltar
        </Button>
        <Button
          colorScheme="blue"
          disabled={errors[step]}
          size="md"
          onClick={() => setHealthSteps(next)}
        >
          Próximo
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default ButtonFooter;
