import Responsible from "./responsible";
import Reason from "./reason";
import FeelingScore from "./feelingScore";
import Cardio from "./cardio";
import Pulm from "./pulm";
import Smoke from "./smoke";
import Osteo from "./osteo";
import Brain from "./brain";
import General from "./general";
import Dependent from "./dependent";
import Fall from "./fall";
import Activity from "./activity";
import Other from "./other";
import Medicine from "./medicine";
import Expectation from "./expectation";
import Welcome from "./welcome";

const Steps = {
  Welcome,
  Responsible,
  Reason,
  FeelingScore,
  Cardio,
  Pulm,
  Smoke,
  Osteo,
  Brain,
  General,
  Dependent,
  Fall,
  Activity,
  Other,
  Medicine,
  Expectation,
};

const StepsArray = [
  Welcome,
  Responsible,
  Reason,
  FeelingScore,
  Cardio,
  Pulm,
  Smoke,
  Osteo,
  Brain,
  General,
  Dependent,
  Fall,
  Activity,
  Medicine,
  Other,
  Expectation,
];

export { StepsArray };

export default Steps;
