import * as Yup from "yup";

const HealthSchema = Yup.object().shape({
  formFilledBy: Yup.string()
    .required("Required")
    .oneOf(["Client", "Responsible"]),
  formResponsibleName: Yup.string().when("formFilledBy", {
    is: "Responsible",
    then: Yup.string().required("Campo obrigatório"),
  }),
  formResponsibleRelation: Yup.string().when("formFilledBy", {
    is: "Responsible",
    then: Yup.string().required("Campo obrigatório"),
  }),

  //Reason
  reason: Yup.string().required("Campo obrigatório"),
  feelingScore: Yup.string().required("Campo obrigatório"),
});

export default HealthSchema;
