import { Stack, Container, Box } from "@chakra-ui/react";
import Space from "components/Space";
import { ICalendar } from "models/Calendar";
import { IResumeCurrent } from "models/Resume";
import Header from "../components/Header";
import DateUtils from "utils/dateUtils";

import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

interface ReportsProps {
  previous: ICalendar;
  current: IResumeCurrent;
}

const parseData = (current: ICalendar, previous: ICalendar) => [
  {
    name: "IMS",
    current: current?.evaluation?.classification?.ims,
    previous: previous?.evaluation?.classification?.ims,
  },
  {
    name: "MRC",
    current: current?.evaluation?.classification?.mrc,
    previous: previous?.evaluation?.classification?.mrc,
  },
  {
    name: "TUG",
    current: current.evaluation?.classification?.tug,
    previous: previous?.evaluation?.classification?.tug,
  },
  {
    name: "Dinamometria",
    current: current?.evaluation?.dynamometry,
    previous: previous?.evaluation?.dynamometry,
  },
  {
    name: "Sit To Stand",
    current: current?.evaluation?.sitToStand,
    previous: previous?.evaluation?.sitToStand,
  },
  {
    name: "Marcha Estacionária",
    current: current?.evaluation?.stationaryMarch,
    previous: previous?.evaluation?.stationaryMarch,
  },
  {
    name: "Berg",
    current: current?.evaluation?.berg,
    previous: previous?.evaluation?.berg,
  },
  {
    name: "katz",
    current: current?.evaluation?.katz,
    previous: previous?.evaluation?.katz,
  },
];

const Reports = ({ current, previous }: ReportsProps) => {
  const dataReport = parseData(current, previous).filter(
    (item) => item.current || item.previous
  );

  console.log(previous);
  const dataAtual = DateUtils.formatStringToBr(current.createdAt, true);
  const dataAnterior = DateUtils.formatStringToBr(previous?.createdAt, true);

  if (dataReport.length === 0) return null;

  return (
    <>
      <Space />
      <Box mt={8} px={8}>
        <Stack
          mb={8}
          spacing={4}
          as={Container}
          maxW={"3xl"}
          textAlign={"center"}
          sx={{
            "@media print": {
              textAlign: "left",
              my: 0,
              mt: 0,
              px: 2,
            },
          }}
        >
          <Header
            title="Gráfico de Performance"
            description="Dados comparando a performance do paciente entre as avaliação atual e a anterior."
          />
        </Stack>
        <Box ml={-14}>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              data={dataReport}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <Legend verticalAlign="top" height={36} />{" "}
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar
                dataKey="current"
                name={`Atual - ${dataAtual}`}
                label={{
                  position: "center",
                  fill: "#FFF",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
                barSize={40}
                fill="#413ea0"
              />
              {dataAnterior && (
                <Bar
                  dataKey="previous"
                  name={`Anterior - ${dataAnterior}`}
                  label={{
                    position: "center",
                    fill: "#FFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                  barSize={40}
                  fill="#050251"
                />
              )}
              {/* <Line type="monotone" dataKey="uv" stroke="#ff7300" />
          <Scatter dataKey="cnt" fill="red" /> */}
            </ComposedChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </>
  );
};

export default Reports;
