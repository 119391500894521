import {
  FormControl,
  FormLabel,
  Box,
  Textarea,
  Heading,
} from "@chakra-ui/react";
import { useFormikContext, FormikProps } from "formik";
import ButtonFooter from "../../buttons";
import { Health } from "../../type";

function Other() {
  const { values, setFieldValue }: FormikProps<Health> = useFormikContext();

  return (
    <>
      <Box mb={4}>
        <Heading my={6} size="lg">
          Conte mais sobre você!
        </Heading>
        <FormControl as="fieldset">
          <FormLabel as="legend">
            Informe algo que não foi listado e você acha que é muito importante
          </FormLabel>
          <Textarea
            value={values["other"]}
            onChange={(event) => setFieldValue("other", event.target.value)}
            size="sm"
          />
        </FormControl>

        <ButtonFooter step="other" />
      </Box>
    </>
  );
}

export default Other;
