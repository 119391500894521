/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable import/prefer-default-export */

export const typeOfCategory = (itemSelected: any) => {
  if (itemSelected.category.name === "Médico")
    return itemSelected.session ? "Retorno" : "Avaliação";
  return itemSelected.session ? "Sessão" : "Avaliação";
};

export const formatWeight = (weight: string, measure?: string) => {
  if (!measure || !weight) return weight;

  if (weight.length >= 4) {
    return weight;
  }

  return parseInt(weight, 10);
};

export const formatMeasureToKg = (weight: string, measure?: string) => {
  if (!measure) return "";

  if (measure === "g" && weight.length <= 3) {
    return "kg";
  }

  return measure;
};

export const status = (itemSelected: any) => {
  const statusType = {
    created: "Iniciado",
    opened: "Em aberto",
    completed: "Concluído",
    canceled: "Cancelado",
  } as any;

  return statusType[itemSelected.status];
};

const classificationTotal = (values: any): number => {
  if (!values) return 0;
  const keys = Object.keys(values);
  const keysValues: number[] = Object.values(values);
  const classificationKeys = [
    "ims",
    "tug",
    "supportUnipodal",
    "mrc",
    "gfm_mi",
    "gfm_ms",
    "sts",
    "cognitive_s5q",
  ];

  const existAllKeys = classificationKeys.every((key: string) =>
    keys.includes(key)
  );

  if (!existAllKeys) return 0;

  const total = keysValues
    .filter((item) => typeof item === "number") // remove faker values
    .reduce((acc: number, curr: number): number => acc + curr, 0);

  return total;
};

const classification = (total = 0) => {
  if (total >= 30)
    return {
      label: "A - Baixo risco de quedas",
      character: "A",
      color: "success",
      description:
        "Sou ativo, compreendo todas as informações, com bom desempenho em testes de Velocidade de Marcha, Equilíbrio e Força Muscular. Caminho bem sozinho por maiores distâncias. Sento e Levanto com velocidade, sem apoio e isso não me deixa cansado. Preciso de segurança mantendo ambiente iluminado, roupas que permitam meus olhos visualizarem meus passos e calçados firmes",
    };
  if (total >= 25 && total < 30) {
    return {
      label: "B - Alto risco de quedas",
      character: "B",
      color: "danger",
      description:
        "Meus passos podem ser curtos e não levanto muito os pés para caminhar.Talvez necessite de andador ou bengala para caminhar, por isso, comigo, todo cuidado é pouco. Preciso de sapatos firmes nos pés e não pode haver obstáculos no chao que me desequilibrem. Compreendo, no entanto, com um pouco de lentidão. Minha força muscular não está 100%, mas consigo me levantar e sentar sozinho.",
    };
  }
  if (total >= 20 && total < 25)
    return {
      label: "C - Alto risco de quedas",
      character: "C",
      color: "danger",
      description:
        "Fico em pé e até consigo caminhar poucos metros, mas, para isso, preciso da ajuda de pelo menos 1 ou 2 pessoas, andando com bastante lentidão. Meus sapatos precisam ser fechados e firmes nos pés. Minha força está reduzida, e só de juntar os pés já me desequilibro um pouco. Sento e levanto vagarosamente, no meu ritmo, com cautela, e minha compreesão poder ser pouca.",
    };
  if (total >= 15 && total < 20)
    return {
      label: "D - Alto risco de quedas",
      character: "D",
      color: "danger",
      description:
        "Consigo dar mínimos 4 passos até a cadeira, ou levantar meu pé do chao quando eu estiver parado. Necessito de supervisao constante ao fazer esses movimentos. Sinto que não tenho muita força porque até a gravidade é pesada, não consigo ter movimentos ativos em algumas posições, e já estrou em pré-fragilidade. Não comprrendo ao certo comandos.",
    };
  if (total >= 10 && total < 15)
    return {
      label: "E - Alto risco de quedas",
      character: "E",
      color: "danger",
      description:
        "Sou um idoso frágil. Não compreendo os comandos. Sento na beira da cama, ou fico em pé com auxílio, por isso tenho maior risco de quedas. Meu tronco tem algum controle, mas não é muito confiável! Minha força pode apresentar esboços de contração, mas necessito de auxílio para a maior parte de atividades. Depois que eu sentar preciso estar usando contenção.",
    };
  if (total > 5 && total < 10)
    return {
      label: "F - Moderado risco de quedas ",
      character: "F",
      color: "basic",
      description:
        "Não me movimento e não realizo nenhuma atividade sozinho, preciso de suporte para trocar de posição na cama, além de ajuda nas transferências ou apoio para ficar sentado. Sentado, preciso usar a contencao pois não tenho um controle de tronco. Meus músculos sao extremamente fracos. Apresento incapacidades, não respondo aos comandos.",
    };
  return {
    label: "",
    description: "",
    character: "",
  };
};

const parseLocation = (locationType: string) => {
  if (locationType === "domicile") return "Domiciliar";
  if (locationType === "clinic") return "Clínica";
  if (locationType === "ILPI") return "ILPI";
  if (locationType === "Hospital") return "hospital";
  return "other";
};

export default {
  status,
  typeOfCategory,
  formatWeight,
  formatMeasureToKg,
  classificationTotal,
  classification,
  parseLocation,
};
