import {
  FormControl,
  FormLabel,
  Box,
  Stack,
  Text,
  CheckboxGroup,
  Checkbox,
  Heading,
} from "@chakra-ui/react";
import { useFormikContext, FormikProps } from "formik";
import ButtonFooter from "../../buttons";
import { Health } from "../../type";

function Osteo() {
  const { values, setFieldValue }: FormikProps<Health> = useFormikContext();

  const DISEASES_OSTEO = [
    "Artrose/artrite no(s) joelho(s)",
    "Artrose/artrite no(s) ombro(s)",
    "Artrose/artrite no quadril",
    "Artrose/artrite na(s) mão(s)",
    "Artrose/artrite na coluna",
    "Fratura COM tratamento cirúrgico",
    "Fratura SEM tratamento cirúrgico",
  ];

  return (
    <Box>
      <Heading my={6} size="lg">
        Sistema Locomotor
      </Heading>
      <FormControl as="fieldset">
        <FormLabel as="legend">Como estão seus ossos e músculos? </FormLabel>

        <Text mb={4}>
          Marque todas as opções que você considerar que tem relação com seu
          estado atual de saúde.
        </Text>

        <Text mb={4}>
          Você poderá também acrescentar algo no final que não tenha aparecido
          por aqui.
        </Text>

        <CheckboxGroup
          value={values["osteo"]}
          onChange={(value) => setFieldValue("osteo", value)}
        >
          <Stack>
            {DISEASES_OSTEO.map((item) => {
              return (
                <Checkbox key={item} value={item}>
                  {item}
                </Checkbox>
              );
            })}
          </Stack>
        </CheckboxGroup>
      </FormControl>
      <ButtonFooter step="osteo" />
    </Box>
  );
}

export default Osteo;
