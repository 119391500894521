/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { IResume } from "models";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const getResume = (
  calendarId: string,
  token: string,
  companyId: string
): Promise<IResume> => {
  const url = `/attendances/evaluation/${calendarId}/resume`;
  const headers = {
    Authorization: `Bearer ${token}`,
    "x-company-id": companyId,
  };
  return api.get(url, { headers }).then((response) => response.data);
};

export default {
  getResume,
};
