import styled from "styled-components";
import { Text } from "@chakra-ui/react";

type TScoreBox = {
  current: boolean;
};

export const ScoreBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ScoreBox = styled.div`
  height: 70px;
  background: ${(props: TScoreBox) => (props.current ? "#16367a" : "#f6f7f9")};
  padding: 5px;
  text-align: center;
  border: 1px solid
    ${(props: TScoreBox) => (props.current ? "#333" : "#dedfe1")};
`;

type TScore = {
  bgColor: string;
};
export const Score = styled.div`
  background-color: ${(props: TScore) => props.bgColor};
  border-radius: 50px;
  margin-top: 5px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`;

export const ScoreText = styled(Text)`
  font-weight: 500;
  color: ${(props: TScoreBox) => (props.current ? "#FFFFFF" : "#0d0d0e")};
`;
