import {
  FormControl,
  FormLabel,
  Box,
  RadioGroup,
  Stack,
  Radio,
  Heading,
} from "@chakra-ui/react";
import { useFormikContext, FormikProps } from "formik";
import ButtonFooter from "../../buttons";
import { Health } from "../../type";

function Fall() {
  const { values, setFieldValue }: FormikProps<Health> = useFormikContext();

  const FALLS_VALUES = [
    {
      label: "Sim, há menos de 6 meses",
      value: "y6-",
    },
    {
      label: "Sim, há mais de 6 meses",
      value: "y6+",
    },
    {
      label: "Não, nunca",
      value: "none",
    },
  ];

  return (
    <>
      <Heading my={6} size="lg">
        Quedas
      </Heading>
      <Box mb={4}>
        <FormControl as="fieldset">
          <FormLabel as="legend">
            Já sofreu alguma queda em algum momento
          </FormLabel>
          <RadioGroup
            value={values["fall"]}
            onChange={(value) => setFieldValue("fall", value)}
          >
            <Stack>
              {FALLS_VALUES.map((item) => {
                return (
                  <Radio key={item.value} value={item.value}>
                    {item.label}
                  </Radio>
                );
              })}
            </Stack>
          </RadioGroup>
        </FormControl>

        <ButtonFooter step="fall" />
      </Box>
    </>
  );
}

export default Fall;
