import {
  FormControl,
  FormLabel,
  Box,
  RadioGroup,
  Stack,
  Radio,
  Heading,
} from "@chakra-ui/react";
import { useFormikContext, FormikProps } from "formik";
import ButtonFooter from "../../buttons";
import { Health } from "../../type";

function Smoke() {
  const { values, setFieldValue }: FormikProps<Health> = useFormikContext();

  const SMOKE = [
    {
      label: "Sim, fumo há mais de 5 anos",
      value: "y5+",
    },
    {
      label: "Sim, fumo há menos de 5 anos",
      value: "y5-",
    },
    {
      label: "Não, parei há mais de 5 anos",
      value: "n5-",
    },
    {
      label: "Não, parei há mais de 5 anos",
      value: "n5+",
    },
    {
      label: "Não, parei há mais de 5 anos",
      value: "none",
    },
  ];

  return (
    <Box>
      <Heading my={6} size="lg">
        Fumante?
      </Heading>
      <FormControl as="fieldset">
        <FormLabel as="legend">Você é fumante ou já foi fumante?</FormLabel>
        <RadioGroup
          value={values["smoke"]}
          onChange={(value) => setFieldValue("smoke", value)}
        >
          <Stack>
            {SMOKE.map((item) => {
              return (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              );
            })}
          </Stack>
        </RadioGroup>
      </FormControl>

      <ButtonFooter step="smoke" />
    </Box>
  );
}

export default Smoke;
