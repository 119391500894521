import { Box, Stack, SimpleGrid } from "@chakra-ui/react";
import { Container } from "rsuite";
import { IEvaluation } from "models";

import Space from "components/Space";
import Header from "../components/Header";
import StatsCard, { StatsCardProps } from "./StatsCard";

interface TestesGeriatricosProps {
  evaluation: IEvaluation;
}

const SensoryOptions = [
  { label: "Pés juntos olhos abertos e superfície estável", value: 0 },
  { label: "Pés juntos olhos fechados e superfície estável", value: 1 },
  { label: "Pés em tandem olhos abertos e superfície estável", value: 2 },
  { label: "Pés em tandem olhos fechados e superfície estável", value: 3 },
  { label: "Apoio Unipodal D olhos abertos e superfície estável", value: 4 },
  { label: "Apoio Unipodal D olhos fechados e superfície estável", value: 5 },
  { label: "Apoio Unipodal E olhos abertos e superfície estável", value: 6 },
  { label: "Apoio Unipodal E olhos fechados e superfície estável", value: 7 },
  { label: "Pés juntos olhos abertos e superfície instável", value: 8 },
  { label: "Pés juntos olhos fechados e superfície instável", value: 9 },
  { label: "Pés em tandem olhos abertos e superfície instável", value: 10 },
  { label: "Pés em tandem olhos fechados e superfície instável", value: 11 },
  { label: "Apoio Unipodal D olhos abertos e superfície instável", value: 12 },
  { label: "Apoio Unipodal D olhos fechados e superfície instável", value: 13 },
  { label: "Apoio Unipodal E olhos abertos e superfície instável", value: 14 },
  { label: "Apoio Unipodal E olhos fechados e superfície instável", value: 15 },
];

const AshworthOptions = [
  { label: "0 - Tônus normal", value: 0 },
  {
    label: "1 - Aumento do tônus no início ou no final do arco de movimento",
    value: 1,
  },
  {
    label:
      "1+ - Aumento do tônus em menos da metade do arco de movimento manifestado por tensão abrupta e seguido por resistência mínima",
    value: 1.5,
  },
  {
    label: "2 - Aumento do tônus em mais da metade do arco de movimento",
    value: 2,
  },
  {
    label: "3 - Partes em flexão ou extensão e movidos com dificuldade",
    value: 3,
  },
  { label: "4 - Partes rígidas em flexão ou extensão", value: 4 },
];

export const SupportUnipodalOptions = [
  { label: "> 10", value: 5 },
  { label: "5 a 10 ", value: 4 },
  { label: "< 5 / redução base", value: 3 },
  { label: "não se aplica IMS 6 ou 5", value: 2.5 },
  { label: "não se aplica IMS 4 ou 3", value: 1.5 },
  { label: "não se aplica IMS 2 ou 1 ou 1", value: 1 },
];

const defaultOptions = [
  { label: "Não realiza", value: 0 },
  { label: "Realiza com ajuda", value: 1 },
  { label: "Realiza", value: 2 },
];

const TestesGeriatricos = ({ evaluation }: TestesGeriatricosProps) => {
  const statCard: StatsCardProps[] = [
    {
      title: "Estado Geral",
      mensure: "pontos",
      stat: evaluation.feelingScore,
    },
    {
      title: "Berg",
      mensure: "pontos",
      stat: evaluation.berg,
    },
    {
      title: "TC6M",
      mensure: "m",
      stat: evaluation.tc6m,
    },
    {
      title: "TUG",
      mensure: "pontos",
      stat: evaluation.tug,
    },
    {
      title: "Sit to Stand",
      mensure: "vezes em 1 min",
      stat: evaluation.sts,
    },
    {
      title: "Teste Unipodal Esquerda",
      mensure: "seg",
      stat: evaluation.tuEsq,
    },
    {
      title: "Teste Unipodal Direita",
      mensure: "seg",
      stat: evaluation.tuDir,
    },
    {
      title: "Alcance Funcional",
      mensure: "cm",
      stat: evaluation.taf,
    },

    {
      title: "Dinamometria",
      mensure: "kgf",
      stat: evaluation.dynamometry,
    },
    {
      title: "Marcha Estacionária",
      mensure: "n vezes",
      stat: evaluation.stationaryMarch,
    },
    {
      title: "IMD (índice de marcha dinâmica)",
      mensure: "pontos",
      stat: evaluation.imd,
    },
    {
      title: "Katz",
      mensure: "pontos",
      stat: evaluation.katz,
    },
    {
      title: "Sit to stand 5 vezes",
      mensure: "seg",
      stat: evaluation.sitToStand5Times,
    },
    {
      title: "Análise da marcha",
      mensure: "",
      stat: evaluation.gaitAnalysis,
    },
    {
      title: "Transferência sentado para em pé",
      stat: defaultOptions[evaluation.sitToStand]?.label,
    },
    {
      title: "Transferência em pé para sentado",
      stat: defaultOptions[evaluation.standToSit]?.label,
    },
    {
      title: "Transferência sentado para deitado",
      stat: defaultOptions[evaluation.sitToLying]?.label,
    },
    {
      title: "Transferência decúbito dorsal para ventral",
      stat: defaultOptions[evaluation.dorsalToVentral]?.label,
    },
    {
      title: "Transferência decúbito ventral para dorsal",
      stat: defaultOptions[evaluation.ventralToDorsal]?.label,
    },

    {
      title: "Integração Sensorial",
      stat: SensoryOptions[evaluation.sensoryIntegration]?.label,
    },

    {
      title: "Escala de Ashworth - MIE",
      stat: AshworthOptions[evaluation.mie]?.label,
    },
    {
      title: "Escala de Ashworth - MSE",
      stat: AshworthOptions[evaluation.mse]?.label,
    },
    {
      title: "Escala de Ashworth - MID",
      stat: AshworthOptions[evaluation.mid]?.label,
    },
    {
      title: "Escala de Ashworth - MSD",
      stat: AshworthOptions[evaluation.msd]?.label,
    },
    {
      title: "Análise de controle postural",
      mensure: "",
      stat: evaluation.posturalAnalysis,
    },
  ];

  if (statCard.some((item) => item.stat === undefined)) return null;

  return (
    <>
      <Space />
      <Stack
        mb={8}
        mt={8}
        spacing={4}
        as={Container}
        maxW="3xl"
        textAlign="center"
        sx={{
          "@media print": {
            textAlign: "left",
            my: 0,
            mt: 0,
            px: 2,
          },
        }}
      >
        <Header
          title="Testes geriátricos"
          description="Dados coletados na avaliação realizada pelo paciente na data informada
          em dados do atendimento."
        />
      </Stack>
      <Box padding={2}>
        <SimpleGrid
          columns={{ base: 2, md: 3 }}
          spacing={{ base: 5, lg: 8 }}
          sx={{
            "@media print": {
              p: 0,
              mt: 2,
              "grid-template-columns": "repeat(4, minmax(0, 1fr))",
            },
          }}
        >
          {statCard.map((item) => {
            return (
              <StatsCard
                key={item.title}
                title={item.title}
                mensure={item.mensure}
                stat={item.stat}
                icon={item?.icon}
              />
            );
          })}
        </SimpleGrid>
      </Box>
    </>
  );
};

export default TestesGeriatricos;
