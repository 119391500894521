export const parseJwt = (token: any) => {
  try {
    const jwt = JSON.parse(atob(token.split(".")[1]));
    const timeNow = Date.now() / 1000;
    if (jwt.exp < timeNow) throw new Error("expired");

    return jwt;
  } catch (e) {
    return null;
  }
};
