import qs from "qs";
import { useEffect } from "react";
import { Box, Container, Flex, Spinner } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import useStore from "store/resume";
import AttendanceService from "services/attendance";
import { Text } from "@chakra-ui/react";
import Logo3ID from "assets/images/3id-logo.png";
import AvaliacaoInicial from "./AvaliacaoInicial";
import DadosAtendimento from "./DadosAtendimento";
import DadosHemodinamicos from "./DadosEmodinamicos";
import DadosHemodinamicosAvg from "./DadosEmodinamicosAvg";
import TestesGeriatricos from "./TestesGeriatricos";
import "./styles.css";
import Reports from "./Reports";
import Header from "./components/Header";
import Space from "components/Space";
import Classificacao from "./Classificacao";

function EvaluationResume(props: any) {
  const token = useStore((state) => state.token);
  const setToken = useStore((state) => state.setToken);

  const query: any = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });

  const { data: resume } = useQuery(
    ["getResume"],
    () =>
      AttendanceService.getResume(
        query.calendarId,
        token.access_token,
        query.companyId
      ),
    { enabled: !!token && !!query.calendarId }
  );

  useEffect(() => {
    setToken(props.location.search);
  }, [props.location.search, setToken]);

  if (!resume || !resume.current) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <Box mt={2}>Buscando dados...</Box>
      </Box>
    );
  }

  return (
    <Container maxW="container.md">
      <Flex
        py={10}
        justifyContent="center"
        alignItems="center"
        sx={{
          "@media print": {
            justifyContent: "space-between",
            width: "100%",
            marginLeft: 0,
            padding: "12px 0",
          },
        }}
      >
        <Box mr={10}>
          <img src={Logo3ID} alt="3ID" width={120} />
        </Box>
        <Box
          padding={2}
          sx={{
            "@media print": {
              display: "",
            },
          }}
        >
          <Text fontSize="xs">3ID Prevenção e Reabilitação geriátrica</Text>
          <Text fontSize="xs">
            Telefone: (61) 3349-7250/ Whatsapp: (61) 99321-1930
          </Text>
          <Text fontSize="xs">
            Endereço: SMHN Quadra 2 ED. Cleo Octavio Sala 802 - Brasília/DF
          </Text>
        </Box>
      </Flex>

      <DadosAtendimento
        person={resume.current.person}
        current={resume.current}
      />

      <Box
        id="avaliacao-inicial"
        my={12}
        sx={{
          "@media print": {
            my: 0,
            padding: 0,
          },
        }}
      >
        <AvaliacaoInicial evaluation={resume.current.evaluation} />
      </Box>

      <Box
        id="dados-hemodicanaicos"
        my={12}
        sx={{
          "@media print": {
            my: 0,
          },
        }}
      >
        <DadosHemodinamicos current={resume.current} />
      </Box>

      <Box
        id="testes-geriatricos"
        my={12}
        sx={{
          "@media print": {
            my: 0,
          },
        }}
      >
        <TestesGeriatricos evaluation={resume.current.evaluation} />
      </Box>

      <Box id="classificacao">
        <Classificacao evaluation={resume.current.evaluation} />
      </Box>

      <Box
        id="dados-hemodicanaicos-avg"
        my={12}
        sx={{
          "@media print": {
            my: 2,
          },
        }}
      >
        <DadosHemodinamicosAvg current={resume.current} />
      </Box>

      <Box className="pagebreak">
        <Reports current={resume.current} previous={resume.previous} />
      </Box>

      {resume.current.evaluation.professionalExternal && (
        <>
          <Space />
          <Box my={12} px={4}>
            <Header
              title="Observações para profissional externo"
              description="Essas obervações são direcionadas a outros profissionais."
            />
            <Box padding={2}>
              <Text>{resume.current.evaluation.professionalExternal}</Text>
            </Box>
          </Box>
        </>
      )}

      <hr />
    </Container>
  );
}

export default EvaluationResume;
