/* eslint-disable global-require */
import * as dateFns from "date-fns";
import human from "humanparser";
import parsePhoneNumber from "libphonenumber-js";
import { IPerson, TPersonAddress } from "models/Person";
import { cpf } from "cpf-cnpj-validator";
import DateUtils from "./dateUtils";
import attendanceUtils from "./attendanceUtils";

function calculateFullAge(date: any) {
  const birthDate = dateFns.parseISO(date);
  if (!dateFns.isValid(birthDate)) return null;
  const { years, months, days } = dateFns.intervalToDuration({
    start: birthDate,
    end: new Date(),
  });
  return { years, months, days };
}

const getCompletePhone = (person: IPerson) => {
  if (!person.phoneNumber) return "Não informado";
  const str = `+55${person.phoneAreaCode}${person.phoneNumber}`;
  const phoneNumber = parsePhoneNumber(str);
  return phoneNumber?.formatNational();
};

const getParsedPhone = (values: string) => {
  let phoneAreaCode = null;
  let phoneNumber = null;

  if (values.length < 4) {
    phoneAreaCode = values;
  }

  if (values.length > 4) {
    phoneAreaCode = values.split(" ")[0].replace(/\(|\)/g, "");
    phoneNumber = values.split(" ")[1].replace("-", "");
  }

  return {
    phoneNumber,
    phoneAreaCode,
  };
};

const getGender = (person: IPerson) =>
  person?.gender === "m" ? "Masculino" : "Feminino";

const getBirthday = (person: IPerson) => {
  if (!person.birthday) return "Não informado";
  return person.birthday
    ? DateUtils.formatStringToBr(person.birthday)
    : "Não informado";
};

const getAge = (person: IPerson) => {
  if (!person.birthday) return null;
  const result = calculateFullAge(person.birthday);
  if (!result) return null;
  return result?.years;
};

const getAddress = (address: TPersonAddress) => {
  if (!address) return "Não cadastrado";
  return address.cep
    ? `    Endereço: ${address.street}
    Cidade: ${address.city}- ${address.state}
    Cep: ${address.cep}`
    : "Não informado";
};

const capitalizeName = (str: string) => {
  if (!str) return "";
  const arr = str.toLocaleLowerCase().split(" ");

  for (let i = 0; i < arr.length; i += 1) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

const getName = (person: IPerson) => human.parseName(person.fullName);

const isBirthday = (person: IPerson) => {
  if (!person.birthday) return null;
  const dateParsed = dateFns.parseISO(person.birthday);
  if (!dateFns.isValid(dateParsed)) return null;

  const dateISO = dateFns.formatISO(dateParsed);
  const date = new Date(dateISO);

  const day = dateFns.getDate(date);
  const month = dateFns.getMonth(date);
  const year = dateFns.getYear(new Date()); // current year

  const birthday = new Date(year, month, day);

  return !dateFns.differenceInDays(birthday, new Date());
};

const isNewUser = (person: IPerson) => {
  if (!person.account.createdAt) return null;
  const dateParsed = dateFns.parseISO(person.account.createdAt);
  if (!dateFns.isValid(dateParsed)) return null;

  const dateISO = dateFns.formatISO(dateParsed);
  const date = new Date(dateISO);

  const qtyOfDays = dateFns.differenceInDays(date, new Date());

  if (qtyOfDays <= 0 && qtyOfDays >= -2) {
    return true;
  }

  return false;
};

const isDisabled = (person: IPerson) => {
  if (person) return person.account.disabled;
  return false;
};

const cpfMaskRemove = (value: string) => cpf.strip(value);
const cpfMaskAdd = (person: IPerson) => {
  if (!person.cpf) return null;
  return cpf.format(person.cpf);
};

const getClassification = (person: IPerson) =>
  person.classification && person.classification.length
    ? attendanceUtils.classification(person.classification.slice(-1)[0])
    : {
        color: "default",
        character: "Ø",
      };

const getFullAge = (person: IPerson) => {
  if (!person.birthday) return "Não informado";
  return person.birthday ? `${getAge(person)} anos` : "Idade: Não informado";
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  capitalizeName,
  getCompletePhone,
  getParsedPhone,
  getGender,
  getBirthday,
  getAge,
  getFullAge,
  getAddress,
  getName,
  calculateFullAge,
  isBirthday,
  isNewUser,
  isDisabled,
  cpfMaskRemove,
  cpfMaskAdd,
  getClassification,
};
