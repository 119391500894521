/* eslint-disable import/no-anonymous-default-export */
import {
  getYear,
  isDate,
  parse,
  parseISO,
  differenceInHours,
  differenceInDays,
  differenceInMinutes,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns-tz';

const timeZone = 'America/Sao_Paulo';

const formatStringToBr = (date: string, showTime = false) => {
  if (!date) return '';

  const newDate = parseISO(date);

  if (!showTime)
    return format(newDate, 'dd/MM/yyyy', {
      timeZone,
    });

  return format(newDate, 'dd/MM/yyyy hh:mm', {
    timeZone,
  });
};

const formatStringToBrTime = (date: string) => {
  const newDate = new Date(date);
  const formattedDate = newDate.toLocaleTimeString('pt-BR', { timeZone });
  return formattedDate;
};

const formatStringToEua = (date: string) => date.split('/').reverse().join('-');

const parseStringToIso = (date: string) => parseISO(date);

const yupBirthDate = (value: any, originalValue: any) => {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'P', new Date(), { locale: ptBR });

  if (getYear(parsedDate).toString().length === 4) {
    return parsedDate;
  }

  return value;
};

const getDifferenceInHours = (startDate: any, endDate: any) => {
  return differenceInHours(startDate, endDate);
};

const getDifferenceInDays = (startDate: any, endDate: any) => {
  return differenceInDays(startDate, endDate);
};

const getDifferenceInMinutes = (startDate: any, endDate: any) => {
  return differenceInMinutes(startDate, endDate);
};

export default {
  formatStringToBr,
  formatStringToBrTime,
  formatStringToEua,
  parseStringToIso,
  yupBirthDate,
  getDifferenceInHours,
  getDifferenceInDays,
  getDifferenceInMinutes,
};
