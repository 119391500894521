import { Stack, SimpleGrid, Flex, Container, Text } from "@chakra-ui/react";
import BloodPressureImg from "assets/images/resume/blood-pressure.png";
import HeartRateImg from "assets/images/resume/heart-rate.png";
import OxyginSaturate from "assets/images/resume/oxygen-saturation.png";
import Space from "components/Space";
import { IResumeCurrent } from "models/Resume";
import Header from "../components/Header";

interface DadosHemodinamicosProps {
  current: IResumeCurrent;
}

const DadosHemodinamicos = ({ current }: DadosHemodinamicosProps) => {
  if (
    !current.heartRateAvgInitial &&
    !current.bloodPressureSysAvgInitial &&
    !current.spo2AvgInitial
  ) {
    return null;
  }
  return (
    <>
      <Space />
      <Flex
        flexDirection="column"
        justifyContent="center"
        mt={5}
        p={{
          base: "2",
          md: "4",
        }}
        sx={{
          "@media print": {
            mt: 2,
            p: 0,
          },
        }}
      >
        <Stack
          mb={8}
          spacing={4}
          as={Container}
          maxW={"3xl"}
          textAlign={"center"}
          sx={{
            "@media print": {
              textAlign: "left",
              my: 0,
              mt: 0,
              p: 0,
            },
          }}
        >
          <Header
            title="IA - Média Hemodinâmicos"
            description="Média dos dados de todos os registros já efetuados no app 3ID,
            resultado é a media dessas medições."
          />
        </Stack>
        <SimpleGrid
          columns={{ base: 2, md: 3 }}
          spacing={10}
          sx={{
            "@media print": {
              m: 0,
              p: 0,
              mt: 2,
              "grid-template-columns": "repeat(3, minmax(0, 1fr))",
            },
          }}
        >
          <Stack>
            <Flex
              w={16}
              h={16}
              align={"center"}
              justify={"center"}
              color={"white"}
              rounded={"full"}
              bg={"gray.100"}
              mb={1}
              sx={{
                "@media print": {
                  display: "none",
                },
              }}
            >
              <img src={BloodPressureImg} alt="Pressão Arterial" />
            </Flex>

            <Text
              fontWeight={600}
              mt={10}
              sx={{
                "@media print": {
                  margin: 0,
                  padding: 0,
                  fontSize: "xs",
                  color: "#343333",
                },
              }}
            >
              Pressão Arterial
            </Text>

            <Text
              color={"gray.600"}
              sx={{
                "@media print": {
                  margin: "0 !important",
                  padding: 0,
                  fontSize: "xs",
                },
              }}
            >
              {current.bloodPressureSysAvgInitial} x{" "}
              {current.bloodPressureDiaAvgInitial} mmhg
            </Text>
          </Stack>

          <Stack>
            <Flex
              w={16}
              h={16}
              align={"center"}
              justify={"center"}
              color={"white"}
              rounded={"full"}
              bg={"gray.100"}
              mb={1}
              sx={{
                "@media print": {
                  display: "none",
                },
              }}
            >
              <img src={OxyginSaturate} alt="Saturação Parcial de Oxigênio" />
            </Flex>
            <Text
              fontWeight={600}
              sx={{
                "@media print": {
                  margin: 0,
                  padding: 0,
                  fontSize: "xs",
                  color: "#343333",
                },
              }}
            >
              Saturação Oxigênio
            </Text>
            <Text
              color={"gray.600"}
              sx={{
                "@media print": {
                  margin: "0 !important",
                  padding: 0,
                  fontSize: "xs",
                },
              }}
            >
              {current.spo2AvgInitial || "∅"}% / {current.spo2AvgFinal || "∅"}%
            </Text>
          </Stack>

          <Stack>
            <Flex
              w={16}
              h={16}
              align={"center"}
              justify={"center"}
              color={"white"}
              rounded={"full"}
              bg={"gray.100"}
              mb={1}
              sx={{
                "@media print": {
                  display: "none",
                },
              }}
            >
              <img src={HeartRateImg} alt="Frequência Cardíaca" />
            </Flex>
            <Text
              fontWeight={600}
              sx={{
                "@media print": {
                  margin: 0,
                  padding: 0,
                  fontSize: "xs",
                  color: "#343333",
                },
              }}
            >
              Frequência Cardíaca
            </Text>
            <Text
              color={"gray.600"}
              sx={{
                "@media print": {
                  margin: "0 !important",
                  padding: 0,
                  fontSize: "xs",
                },
              }}
            >
              {current.heartRateAvgInitial || "∅"} bpm /{" "}
              {current.heartRateAvgFinal || "∅"} bpm
            </Text>
          </Stack>
        </SimpleGrid>
      </Flex>
    </>
  );
};

export default DadosHemodinamicos;
