import React, { Suspense } from "react";
import HealthNew from "./pages/health";
import HeartRate from "./pages/reports/heartRate";
import FeelingRate from "./pages/reports/feelingRate";
import EvaluationResume from "./pages/evaluation/resume";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRoot } from "react-dom/client";

const queryClient = new QueryClient();

const App = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider>
          <Suspense fallback="Loading...">
            <Router>
              <Switch>
                <Route
                  exact
                  path="/evaluation/resume"
                  component={EvaluationResume}
                />
                <Route exact path="/health/new" component={HealthNew} />
                <Route exact path="/reports/heart-rate" component={HeartRate} />
                <Route
                  exact
                  path="/reports/feeling-rate"
                  component={FeelingRate}
                />
                <Route
                  exact
                  path="*"
                  render={() => <div> Not found page </div>}
                />
              </Switch>
            </Router>
          </Suspense>
        </ChakraProvider>
      </QueryClientProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
