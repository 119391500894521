import {
  FormControl,
  FormLabel,
  Box,
  RadioGroup,
  Stack,
  Radio,
  Heading,
} from "@chakra-ui/react";
import { useFormikContext, FormikProps } from "formik";
import ButtonFooter from "../../buttons";
import { Health } from "../../type";

function Activity() {
  const { values, setFieldValue }: FormikProps<Health> = useFormikContext();

  const ACTIVITIES_VALUES = [
    {
      label: "Sim",
      value: "y",
    },
    {
      label: "Não, parei há menos de 6 meses",
      value: "n6-",
    },
    {
      label: "Não, parei há mais de 6 meses",
      value: "n6+",
    },
  ];

  return (
    <Box>
      <Heading my={6} size="lg">
        Prática de atividade física?
      </Heading>
      <FormControl as="fieldset">
        <FormLabel as="legend">
          Realiza alguma atividade física atualmente?
        </FormLabel>
        <RadioGroup
          value={values["active"]}
          onChange={(value) => setFieldValue("active", value)}
        >
          <Stack>
            {ACTIVITIES_VALUES.map((item) => {
              return (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              );
            })}
          </Stack>
        </RadioGroup>
      </FormControl>

      <ButtonFooter step="activity" />
    </Box>
  );
}

export default Activity;
