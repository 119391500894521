import qs from "qs";
import ReportGraph from "containers/ReportGraph";

const filters = qs.stringify({
  key: "createdAt",
  value: "heartRate",
  valueFinal: "heartRate_final",
});

const HeartRate = ({ location }: any) => {
  return (
    <ReportGraph
      type="GroupBar"
      title="Freq. Cardíaca"
      filters={filters}
      location={location}
    />
  );
};

export default HeartRate;
